/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Box,
  IconButton,
  useTheme,
  Link,
  Breadcrumbs,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import "moment/locale/pt";
import MUIDataTable from "mui-datatables";

// Components
import FooterLinks from "../../../Global/Components/Footer";

//Icons
import FeatherIcon from "feather-icons-react";
import GetAllAdesoes from "../../../utils/redux/actions/plus/GetAllAdesoes";
import { format } from "date-fns";
import SideDrawer from "./components/drawer";
import GetAdesaoById from "../../../utils/redux/actions/plus/GetAdesaoById";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "150px",
    height: "40px",
    fontWeight: "900",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    height: 40,
    borderTop: "4px solid transparent",
    borderBottom: "4px solid transparent",

    "&:hover": {
      borderRight: "4px solid #E21450",
    },
  },
}));

const ColorStateCube = ({ color }) => {
  return (
    <div
      style={{
        height: "10px",
        width: "10px",
        backgroundColor: color,
        marginRight: "10px",
        borderRadius: "3px",
      }}
    />
  );
};

// ############################################################## //
// ####################  Window Dimensions  ##################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const PlusAdesao = () => {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");

  const adesoesState = useSelector((state) => state.plus.adesoes);

  const fetchAdesoes = () => {
    dispatch(GetAllAdesoes());
  };

  const fetchAdesaoById = (id) => {
    dispatch(GetAdesaoById(id));
  };

  useEffect(() => {
    fetchAdesoes();
  }, []);

  const [adesao, setAdesao] = useState({
    nome: "",
    apelido: "",
    telemovel: "",
    email: "",
    nif: "",
    numero_documento: "",
    validade_documento: "",
    certidao_permanente: "",
    tarifaSocial: "",
    titular_contrato_anterior: "",
    preferencia_contacto: "",
    morada: "",
    cod_postal: "",
    localidade: "",
    nova_ligacao: "",
    fornecimento_energia: "",
    is_electricidade_gas: "",
    isElectricidade: "",
    isGas: "",
    isMaisEnergia: "",
    tipotarifa: "",
    potencia: "",
    ciclo: "",
    escalao: "",
    aceito_condicoes_gerais: "",
    observacoes: "",
    data: "",
    ynb_adesao_cpe: "",
    ynb_adesao_cui: "",
    ynb_adesao_flag_inspecao_gas: "",
    ynb_adesao_flag_fornecimento_gas: "",
    ynb_adesao_fatura_gas: "",
    ynb_adesao_comprovativo_iban_file: "",
    ynb_adesao_fatura_eleticidade_file: "",
    ynb_adesao_outros_documentos_file: "",
  });

  const resetAdesao = () => {
    setAdesao({
      id: "",
      nome: "",
      apelido: "",
      telemovel: "",
      email: "",
      nif: "",
      numero_documento: "",
      validade_documento: "",
      certidao_permanente: "",
      tarifaSocial: "",
      titular_contrato_anterior: "",
      preferencia_contacto: "",
      morada: "",
      cod_postal: "",
      localidade: "",
      nova_ligacao: "",
      fornecimento_energia: "",
      is_electricidade_gas: "",
      isElectricidade: "",
      isGas: "",
      isMaisEnergia: "",
      tipotarifa: "",
      potencia: "",
      ciclo: "",
      escalao: "",
      aceito_condicoes_gerais: "",
      observacoes: "",
      data: "",
      ynb_adesao_cpe: "",
      ynb_adesao_cui: "",
      ynb_adesao_flag_inspecao_gas: "",
      ynb_adesao_flag_fornecimento_gas: "",
      ynb_adesao_fatura_gas: "",
      ynb_adesao_comprovativo_iban_file: "",
      ynb_adesao_fatura_eleticidade_file: "",
      ynb_adesao_outros_documentos_file: "",
    });
  };

  // ############################################################## //
  // ########################  Drawer  ############################ //
  // ############################################################## //

  const [drawerWidth, setDrawerWidth] = useState("90vw");
  const dimensions = useWindowDimensions();
  const [state, setState] = useState({
    right: false,
  });
  const [drawerState, setDrawerState] = useState(null);
  const toggleDrawer = (anchor, open, id) => {
    console.log(id);
    if (open == false) {
      setState({ ...state, [anchor]: open });
      // setCredenciacaoId(null);
      resetAdesao();
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
      // setCredenciacaoId(id);
      fetchAdesaoById(id);
    }
  };

  // ############################################################## //
  // ##################  MUI Datatable columns  ################### //
  // ############################################################## //

  const renderTarifa = (value) => {
    switch (value) {
      case "simples":
        return "Simples";
      case "bi_horario":
        return "Bi-Horário";
      case "tri_horario":
        return "Tri-Horário";
      default:
        break;
    }
  };

  const renderEscalao = (value) => {
    switch (value) {
      case "escalao1":
        return "Escalão 1";
      case "escalao2":
        return "Escalão 2";
      case "escalao3":
        return "Escalão 3";
      default:
        break;
    }
  };

  const columns = [
    {
      name: "id",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "nome",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const apelido = tableMeta.rowData[2];
          return (
            <div className="table-btns-tiposatrasos">
              <Typography>{`${value} ${apelido}`}</Typography>
            </div>
          );
        },
      },
    },
    {
      name: "apelido",
      label: "Apelido",
      options: {
        display: false,
      },
    },
    {
      name: "telemovel",
      label: "Telemóvel",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "nif",
      label: "NIF",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ynb_adesao_cpe",
      label: "CPE",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                <Typography>N/D</Typography>
              ) : (
                <Typography>{value}</Typography>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "ynb_adesao_cui",
      label: "CUI",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                <Typography>N/D</Typography>
              ) : (
                <Typography>{value}</Typography>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "servico",
      label: "Serviço",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const isMaisEnergia = tableMeta.rowData[14];
          const isGas = tableMeta.rowData[15];
          const isEletricidade = tableMeta.rowData[16];
          const isEletricidadeGas = tableMeta.rowData[17];
          return (
            <div className="table-btns-tiposatrasos">
              {isMaisEnergia == 1 ? (
                <Typography>Mais Energia</Typography>
              ) : isGas == 1 ? (
                <Typography>Gás</Typography>
              ) : isEletricidade == 1 ? (
                <Typography>Eletricidade</Typography>
              ) : isEletricidadeGas == 1 ? (
                <Typography>Eletricidade e Gás</Typography>
              ) : (
                <Typography>N/D</Typography>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "tipotarifa",
      label: "Tarifa",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              <Typography>{renderTarifa(value)}</Typography>
            </div>
          );
        },
      },
    },
    {
      name: "potencia",
      label: "Potência",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ciclo",
      label: "Ciclo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "escalao",
      label: "Escalão",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              <Typography>{renderEscalao(value)}</Typography>
            </div>
          );
        },
      },
    },
    {
      name: "data",
      label: "Data",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { width: "5%" },
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          return (
            <div style={{ display: "flex", float: "right" }}>
              <IconButton
                onClick={() => {
                  setDrawerState("watch");
                  toggleDrawer("right", true, id);
                }}
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
              >
                <FeatherIcon
                  icon="eye"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
            </div>
          );
        },
      },
    },
    {
      name: "isMaisEnergia",
      label: "isMaisEnergia",
      options: {
        display: false,
      },
    },
    {
      name: "isGas",
      label: "isGas",
      options: {
        display: false,
      },
    },
    {
      name: "isElectricidade",
      label: "isEletricidade",
      options: {
        display: false,
      },
    },
    {
      name: "is_electricidade_gas",
      label: "isEletricidadeGas",
      options: {
        display: false,
      },
    },
  ];

  // ############################################################## //
  // ##################  MUI Datatable Options  ################### //
  // ############################################################## //

  const searchText =
    location.state && location.state.funcionarioNome
      ? location.state.funcionarioNome
      : null;

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    searchText: searchText,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mr: 10,
          width: "100%",
        }}
      >
        <SideDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          drawerState={drawerState}
          drawerWidth={drawerWidth}
          dimensions={dimensions}
          adesao={adesao}
          setAdesao={setAdesao}
        />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              mx: 5,
              mt: 5,
              width: "100%",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/construcoes/obras">
                Gestão Comercial
              </Link>
              <Typography color="text.primary">Lista de Adesões</Typography>
            </Breadcrumbs>
          </Box>
          <div
            style={{
              margin: "0px 40px 40px 40px",
              width: "100%",
            }}
          >
            <MUIDataTable
              title={
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  className="text-underline"
                >
                  Lista de Adesões
                </span>
              }
              data={adesoesState || []}
              columns={columns}
              options={options}
            />
          </div>
          <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default PlusAdesao;
