/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ptLocale from "date-fns/locale/pt";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  blurredText: {
    color: "transparent", // Make the text transparent
    textShadow: "0 0 10px #000", // Apply a text shadow to create the blur effect
    userSelect: "none", // Prevent text selection
  },
  disabledTextField: {
    opacity: 0.7,
    pointerEvents: "none",
    userSelect: "none", // Adjust opacity to indicate the disabled state
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "transparent",
    },
  },
  textField: {
    userSelect: "none",
  },
});

const Form = ({ toggleDrawer, drawerWidth, dimensions, adesao, setAdesao }) => {
  const classes = useStyles();

  const [adesaoData, setAdesaoData] = useState();
  const [adesaoValiDoc, setAdesaoValiDoc] = useState();

  // ############################################################## //
  // ####################  Adesão Selecionada  #################### //
  // ############################################################## //

  const renderTarifa = (value) => {
    switch (value) {
      case "simples":
        return "Simples";
      case "bi_horario":
        return "Bi-Horário";
      case "tri_horario":
        return "Tri-Horário";
      default:
        break;
    }
  };

  const renderEscalao = (value) => {
    switch (value) {
      case "escalao1":
        return "Escalão 1";
      case "escalao2":
        return "Escalão 2";
      case "escalao3":
        return "Escalão 3";
      default:
        break;
    }
  };

  const renderPrefContacto = (value) => {
    switch (value) {
      case "email":
        return "Escalão 1";
      case "escalao2":
        return "Escalão 2";
      case "escalao3":
        return "Escalão 3";
      default:
        break;
    }
  };

  const adesaoSelecionada = useSelector(
    (state) => state && state.plus && state.plus.adesao
  );

  useEffect(() => {
    if (adesaoSelecionada) {
      setAdesao({
        nome: adesaoSelecionada.nome,
        apelido: adesaoSelecionada.apelido,
        telemovel: adesaoSelecionada.telemovel,
        email: adesaoSelecionada.email,
        nif: adesaoSelecionada.nif,
        numero_documento: adesaoSelecionada.numero_documento,
        // validade_documento: adesaoSelecionada.validade_documento,
        certidao_permanente: adesaoSelecionada.certidao_permanente,
        tarifaSocial: adesaoSelecionada.tarifaSocial == 0 ? "Não" : "Sim",
        titular_contrato_anterior:
          adesaoSelecionada.titular_contrato_anterior == 0 ? "Não" : "Sim",
        preferencia_contacto: adesaoSelecionada.preferencia_contacto,
        morada: adesaoSelecionada.morada,
        cod_postal: adesaoSelecionada.cod_postal,
        localidade: adesaoSelecionada.localidade,
        nova_ligacao: adesaoSelecionada.nova_ligacao == 0 ? "Não" : "Sim",
        fornecimento_energia:
          adesaoSelecionada.fornecimento_energia == 0 ? "Não" : "Sim",
        is_electricidade_gas: adesaoSelecionada.is_electricidade_gas,
        isElectricidade: adesaoSelecionada.isElectricidade,
        isGas: adesaoSelecionada.isGas,
        isMaisEnergia: adesaoSelecionada.isMaisEnergia,
        tipotarifa: renderTarifa(adesaoSelecionada.tipotarifa),
        potencia: adesaoSelecionada.potencia,
        ciclo: adesaoSelecionada.ciclo,
        escalao: renderEscalao(adesaoSelecionada.escalao),
        aceito_condicoes_gerais: adesaoSelecionada.aceito_condicoes_gerais,
        observacoes: adesaoSelecionada.observacoes,
        ynb_adesao_cpe: adesaoSelecionada.ynb_adesao_cpe,
        ynb_adesao_cui: adesaoSelecionada.ynb_adesao_cui,
        ynb_adesao_flag_inspecao_gas:
          adesaoSelecionada.ynb_adesao_flag_inspecao_gas,
        ynb_adesao_flag_fornecimento_gas:
          adesaoSelecionada.ynb_adesao_flag_fornecimento_gas,
        ynb_adesao_fatura_gas: adesaoSelecionada.ynb_adesao_fatura_gas,
        ynb_adesao_comprovativo_iban_file:
          adesaoSelecionada.ynb_adesao_comprovativo_iban_file,
        ynb_adesao_fatura_eleticidade_file:
          adesaoSelecionada.ynb_adesao_fatura_eleticidade_file,
        ynb_adesao_outros_documentos_file:
          adesaoSelecionada.ynb_adesao_outros_documentos_file,
      });
      setAdesaoData(new Date(adesaoSelecionada.data));
      setAdesaoValiDoc(new Date(adesaoSelecionada.validade_documento));
    }
  }, [adesaoSelecionada, setAdesao]);

  // ############################################################## //
  // ###############  Handles Change and Submit  ################## //
  // ############################################################## //

  const handleAdesaoData = (date) => {
    setAdesaoData(date);
  };

  const handleAdesaoValiDoc = (date) => {
    setAdesaoData(date);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setAdesao({
      ...adesao,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        nome: adesaoSelecionada.nome,
        apelido: adesaoSelecionada.apelido,
        telemovel: adesaoSelecionada.telemovel,
        email: adesaoSelecionada.email,
        nif: adesaoSelecionada.nif,
        numero_documento: adesaoSelecionada.numero_documento,
        validade_documento: adesaoValiDoc,
        certidao_permanente: adesaoSelecionada.certidao_permanente,
        tarifaSocial: adesaoSelecionada.tarifaSocial,
        titular_contrato_anterior: adesaoSelecionada.titular_contrato_anterior,
        preferencia_contacto: adesaoSelecionada.preferencia_contacto,
        morada: adesaoSelecionada.morada,
        cod_postal: adesaoSelecionada.cod_postal,
        localidade: adesaoSelecionada.localidade,
        nova_ligacao: adesaoSelecionada.nova_ligacao,
        fornecimento_energia: adesaoSelecionada.fornecimento_energia,
        is_electricidade_gas: adesaoSelecionada.is_electricidade_gas,
        isElectricidade: adesaoSelecionada.isElectricidade,
        isGas: adesaoSelecionada.isGas,
        isMaisEnergia: adesaoSelecionada.isMaisEnergia,
        tipotarifa: renderTarifa(adesaoSelecionada.tipotarifa),
        potencia: adesaoSelecionada.potencia,
        ciclo: adesaoSelecionada.ciclo,
        escalao: renderEscalao(adesaoSelecionada.escalao),
        aceito_condicoes_gerais: adesaoSelecionada.aceito_condicoes_gerais,
        observacoes: adesaoSelecionada.observacoes,
        data: adesaoData,
        ynb_adesao_cpe: adesaoSelecionada.ynb_adesao_cpe,
        ynb_adesao_cui: adesaoSelecionada.ynb_adesao_cui,
        ynb_adesao_flag_inspecao_gas:
          adesaoSelecionada.ynb_adesao_flag_inspecao_gas,
        ynb_adesao_flag_fornecimento_gas:
          adesaoSelecionada.ynb_adesao_flag_fornecimento_gas,
      },
    ];

    console.log(ydata);

    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const message = {
      isOpen: true,
      message: "Credenciação editada com sucesso!!",
      type: "success",
    };

    // if (verifyFormPerm()) {
    // dispatch(
    //   EditCredenciacao(
    //     ydata,
    //     credenciacaoSelecionada.id_crede_elementos_credenciacao,
    //     copiaCredenciacao,
    //     null,
    //     ficheiros,
    //     datePickerValues,
    //     toggleDrawer,
    //     ShowNotification,
    //     message,
    //     errorMessage
    //   )
    // ).then(() => {
    //   toggleDrawer("right", false);
    //   fetchCredenciacoes();
    //   dispatch(
    //     NotifyCredenciacoes(
    //       credenciacaoSelecionada.id_crede_elementos_credenciacao,
    //       estado.id
    //     )
    //   );
    // });
    // } else {
    //   // dispatch(ShowNotification(warningMessage));
    //   ydata[0] = { ...ydata[0], rascunho: 1 };
    //   handleOpen(e, ydata);
    // }
  };

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => toggleDrawer("right", false)}
        >
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Adesões</div>
        </Box>
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box> */}
      </Box>
      <Card
        sx={{
          m: 5,
          p: 2,
          boxShadow: "0px 10px 15px lightgrey",
        }}
      >
        <Box
          sx={{
            m: 2,
          }}
        >
          <h4
            style={{
              fontSize: "22px",
              width: "100%",
              marginBottom: "0px",
            }}
            className="text-underline"
          >
            Dados Adesão
          </h4>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Nome
            </Typography>
            <TextField
              name="nome"
              size="small"
              variant="outlined"
              value={adesao.nome}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Apelido
            </Typography>
            <TextField
              name="apelido"
              size="small"
              variant="outlined"
              value={adesao.apelido}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Telemóvel
            </Typography>
            <TextField
              name="telemovel"
              size="small"
              variant="outlined"
              value={adesao.telemovel}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Email
            </Typography>
            <TextField
              name="email"
              size="small"
              variant="outlined"
              value={adesao.email}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              NIF
            </Typography>
            <TextField
              name="nif"
              size="small"
              variant="outlined"
              value={adesao.nif}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Nº Doc. Identificação
            </Typography>
            <TextField
              name="numero_documento"
              size="small"
              variant="outlined"
              value={adesao.numero_documento}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={ptLocale}
            >
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Validade Doc. Id.
              </Typography>
              <Box sx={{ display: "flex" }}>
                <DatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  okLabel="ok"
                  cancelLabel="cancelar"
                  invalidDateMessage="Data Inválida"
                  value={adesaoValiDoc}
                  onChange={handleAdesaoValiDoc}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "dd/mm/aaaa",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      sx={{ width: "200px" }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Certidão Permanente
            </Typography>
            <TextField
              name="certidao_permanente"
              size="small"
              variant="outlined"
              value={adesao.certidao_permanente}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Tarifa Social
            </Typography>
            <TextField
              name="tarifaSocial"
              size="small"
              variant="outlined"
              value={adesao.tarifaSocial}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Titular do Contrato Anterior
            </Typography>
            <TextField
              name="titular_contrato_anterior"
              size="small"
              variant="outlined"
              value={adesao.titular_contrato_anterior}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Preferência de contacto
            </Typography>
            <TextField
              name="preferencia_contacto"
              size="small"
              variant="outlined"
              value={adesao.preferencia_contacto}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Morada
            </Typography>
            <TextField
              name="morada"
              size="small"
              variant="outlined"
              value={adesao.morada}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Cód Postal
            </Typography>
            <TextField
              name="cod_postal"
              size="small"
              variant="outlined"
              value={adesao.cod_postal}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Localidade
            </Typography>
            <TextField
              name="localidade"
              size="small"
              variant="outlined"
              value={adesao.localidade}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Nova Ligação
            </Typography>
            <TextField
              name="nova_ligacao"
              size="small"
              variant="outlined"
              value={adesao.nova_ligacao}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Fornecimento de Energia
            </Typography>
            <TextField
              name="fornecimento_energia"
              size="small"
              variant="outlined"
              value={adesao.fornecimento_energia}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              CPE
            </Typography>
            <TextField
              name="ynb_adesao_cpe"
              size="small"
              variant="outlined"
              value={adesao.ynb_adesao_cpe}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Serviço
            </Typography>
            <TextField
              name="pedido_nr"
              size="small"
              variant="outlined"
              value={adesao.pedido_nr}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Tipo de Tarifa
            </Typography>
            <TextField
              name="tipotarifa"
              size="small"
              variant="outlined"
              value={adesao.tipotarifa}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Potência
            </Typography>
            <TextField
              name="potencia"
              size="small"
              variant="outlined"
              value={adesao.potencia}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Ciclo
            </Typography>
            <TextField
              name="ciclo"
              size="small"
              variant="outlined"
              value={adesao.ciclo}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Observações
            </Typography>
            <TextField
              name="observacoes"
              size="small"
              variant="outlined"
              value={adesao.observacoes}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={ptLocale}
            >
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Data
              </Typography>
              <Box sx={{ display: "flex" }}>
                <DatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  okLabel="ok"
                  cancelLabel="cancelar"
                  invalidDateMessage="Data Inválida"
                  value={adesaoData}
                  onChange={handleAdesaoData}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "dd/mm/aaaa",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      sx={{ width: "200px" }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box>
        {/* <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Fatura Eletricidade
            </Typography>
            <TextField
              name="ynb_adesao_fatura_eleticidade_file"
              size="small"
              variant="outlined"
              value={adesao.ynb_adesao_fatura_eleticidade_file}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Comprovativo IBAN
            </Typography>
            <TextField
              name="ynb_adesao_comprovativo_iban_file"
              size="small"
              variant="outlined"
              value={adesao.ynb_adesao_comprovativo_iban_file}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box> */}
      </Card>
    </div>
  );
};

export default Form;
