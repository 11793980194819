import Instance from "../../../Axios";

const GetAllAdesoes = () => async (dispatch) => {
  await Instance()
    .get(`/plus/adesoes`)
    .then(({ data }) => {
      dispatch({ type: "GET_ALL_ADESOES", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetAllAdesoes;
