import { plusInitialState } from "../initialState/plus";

const plusReducer = (state = plusInitialState, { type, payload }) => {
  switch (type) {
    case "GET_ALL_ADESOES":
      return {
        ...state,
        adesoes: payload,
      };
    case "GET_ADESAO_BY_ID":
      return {
        ...state,
        adesao: payload,
      };

    default:
      return state;
  }
};

export default plusReducer;
